import TomSelect from 'tom-select';
import {Controller} from '@hotwired/stimulus';

/**
 * Динамический обновляет значения в TomSelect
 * Смотрит, какое элемент передан в аттрибуте data-focus-element, и на его событие change меняет свои значения
 * Данные для фильтрации хранятся в аттрибуте: data-filter-values
 *
 * {
 *     parent_id1: [
 *         {id: 1, name: "name1"},
 *         {id: 2, name: "name2"}
 *     ],
 *     parent_id2: [
 *         {id: 1, name: "name1"}
 *     ]
 * }
 */
export default class extends Controller {
    connect() {
        window.onload = () => {
            document.querySelectorAll('[data-filter-values]').forEach(() => {
                let select = document.querySelector('#Notice_services');
                let control = select.tomselect;

                const filter = JSON.parse(select.getAttribute('data-filter-values'));
                const focus = select.getAttribute('data-focus-element');
                const element = document.querySelector(focus);
                const currentId = element.value;

                control.clearOptions();

                if (filter.hasOwnProperty(currentId)) {
                    for (let i in filter[currentId]) {
                        const value = filter[currentId][i];
                        control.addOption({value: value.id, text: value.name});
                    }
                }

                control.refreshOptions(false);

                element.addEventListener('change', (e) => {
                    const channelId = e.target.value;

                    control.clear();
                    control.clearOptions();

                    if (filter.hasOwnProperty(channelId)) {
                        for (let i in filter[channelId]) {
                            const value = filter[channelId][i];
                            control.addOption({value: value.id, text: value.name});
                        }
                    }
                    control.refreshOptions(false);
                });
            });
        };
    }
}