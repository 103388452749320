import Modal from './components/modal';

export default class mercury {
    static enable() {
        const mercureUrlElement = document.getElementById("mercure-url");

        if (mercureUrlElement !== null) {
            const url = JSON.parse(mercureUrlElement.textContent);
            const eventSource = new EventSource(url);

            eventSource.onmessage = async function (message) {
                const json = JSON.parse(message.data);
                if (json.id === 'notification') {
                    let options = {
                        type: 'alert',
                        callback: () => {
                            fetch('/profile/notification/mark-read/'+json.data.id, {
                                method: 'POST',
                            });
                        },
                    };

                    if (json.data.hasOwnProperty('options')) {
                        options = {...options, ...json.data.options};
                    }

                    const modal = new Modal(
                        json.data.title,
                        json.data.message,
                        options
                    );

                    modal.show();

                    let response = await fetch('/profile/notification/latest?stream=1');

                    if (response.status === 200) {
                        const html = await response.text();
                        Turbo.renderStreamMessage(html);
                    }
                }
            }

            const searchParams = new URLSearchParams(window.location.search);
            if (searchParams.has('notification[title]') && searchParams.has('notification[message]')) {
                const options = {
                    type: 'alert'
                };

                if (searchParams.has('notification[type]')) {
                    options.type = searchParams.has('notification[type]');
                }


                const modal = new Modal(
                    searchParams.get('notification[title]'),
                    searchParams.get('notification[message]'),
                    options
                );

                modal.show();

                const url = new URL(window.location)
                url.searchParams.delete('notification[title]')
                url.searchParams.delete('notification[message]')

                history.pushState(null, '', url);
            }
        }
    }
}