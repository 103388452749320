import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    connect() {
        const parent = this.element;
        let currentInt = 0;

        this.element.querySelector('[data-fill-add-input]').addEventListener('click', (e) => {
            const inputTemplate = e.target.getAttribute('data-fill-add-input');

            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = inputTemplate;

            // Получаем первый дочерний элемент (input) и извлекаем атрибут name
            const currentElement = tempDiv.firstElementChild;
            const inputElement = currentElement.querySelector('input');

            if (!inputElement || !inputElement.hasAttribute('name')) {
                console.error('Не удалось извлечь имя поля из шаблона.');
                return;
            }

            const fieldName = inputElement.getAttribute('name').match(/^([^[]+)/);
            if (!fieldName) {
                console.error('Неверный формат атрибута name в шаблоне.');
                return;
            }

            if (currentInt === 0) {
                // Находим все существующие элементы с этим именем
                const existingInputs = parent.querySelectorAll(`input[name^="${fieldName[0]}"]`);
                currentInt = existingInputs.length; // Определяем новый ключ
            } else {
                currentInt++;
            }

            // Создаем новый HTML-элемент на основе шаблона
            const newInputHtml = inputTemplate.replace(/{key}/g, currentInt);

            // Вставляем новый элемент в нужное место (например, перед кнопкой)
            // Здесь нужно указать правильный контейнер для добавления
            const container = parent.querySelector('[data-fill-items]'); // или другой родительский элемент
            container.insertAdjacentHTML('beforeend', newInputHtml);
        });

        this.element.addEventListener('click', (e) => {
            const target = e.target.closest('[data-fill-delete]');
            // Проверяем, является ли целевой элемент элементом с атрибутом data-fill-delete

            if (target) {
                const id = target.getAttribute('data-fill-delete');
                const elementToRemove = document.querySelector('#' + id);

                if (elementToRemove) {
                    elementToRemove.remove(); // Удаляем элемент
                }
            }
        });
    }

    disconnect() {

    }
}